export let mnist_strategies = {
  num_labeled: [
    5000.0,
    6000.0,
    7000.0,
    8000.0,
    9000.0,
    10000.0,
    11000.0,
    12000.0,
    13000.0,
  ],
  random: {
    accuracy: [
      0.923,
      0.9468,
      0.9379,
      0.9551,
      0.9558,
      0.955,
      0.958,
      0.9664,
      0.9635,
    ],
  },
  entropy: {
    accuracy: [
      0.923,
      0.9485,
      0.9589,
      0.96,
      0.9744,
      0.9702,
      0.9774,
      0.9764,
      0.9782,
    ],
  },
  adversarial: {
    accuracy: [
      0.923,
      0.9534,
      0.9558,
      0.9691,
      0.9743,
      0.9716,
      0.9775,
      0.9801,
      0.9795,
    ],
  },
  ensemble: {
    accuracy: [
      0.923,
      0.9554,
      0.967,
      0.9731,
      0.9748,
      0.979,
      0.9812,
      0.9848,
      0.9829,
    ],
  },
}
