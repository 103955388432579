export let caltech_strategies = {
  num_labeled: [300.0, 350.0, 400.0, 450.0, 500.0, 550.0, 600.0, 650.0, 700.0],
  random: {
    accuracy: [
      0.7547169811320755,
      0.7971698113207547,
      0.8490566037735849,
      0.8632075471698113,
      0.8490566037735849,
      0.8726415094339622,
      0.8915094339622641,
      0.8584905660377359,
      0.910377358490566,
    ],
  },
  entropy: {
    accuracy: [
      0.7547169811320755,
      0.8349056603773585,
      0.8066037735849056,
      0.8490566037735849,
      0.8632075471698113,
      0.8773584905660378,
      0.8773584905660378,
      0.9056603773584906,
      0.8726415094339622,
    ],
  },
  adversarial: {
    accuracy: [
      0.7547169811320755,
      0.7971698113207547,
      0.8537735849056604,
      0.8867924528301887,
      0.8679245283018868,
      0.8726415094339622,
      0.8773584905660378,
      0.8584905660377359,
      0.9056603773584906,
    ],
  },
  ensemble: {
    accuracy: [
      0.7547169811320755,
      0.9339622641509434,
      0.9339622641509434,
      0.9292452830188679,
      0.9339622641509434,
      0.9481132075471698,
      0.9292452830188679,
      0.9339622641509434,
      0.9245283018867925,
    ],
  },
}
