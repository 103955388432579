import React, { Component } from 'react'
import Data from './Data'
import './App.css'

class App extends Component {
  render() {
    return <Data />
  }
}

export default App
