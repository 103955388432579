export let quickdraw_strategies = {
  num_labeled: [
    5000.0,
    6000.0,
    7000.0,
    8000.0,
    9000.0,
    10000.0,
    11000.0,
    12000.0,
    13000.0,
  ],
  random: {
    accuracy: [
      0.7685568264784619,
      0.8011681674373327,
      0.7949622779265028,
      0.8040885860306644,
      0.8141275249452421,
      0.823192990995376,
      0.8260525675346799,
      0.831284984181066,
      0.8297639328303723,
    ],
  },
  entropy: {
    accuracy: [
      0.7685568264784619,
      0.7963008031151132,
      0.7894256510099781,
      0.8050012168410805,
      0.8212460452664881,
      0.8202725724020443,
      0.8123631053784376,
      0.8289729861280117,
      0.8267826721830129,
    ],
  },
  adversarial: {
    accuracy: [
      0.7685568264784619,
      0.805609637381358,
      0.8077999513263567,
      0.8172913117546848,
      0.8289729861280117,
      0.8298856169384279,
      0.8343270868824532,
      0.8541615964954977,
      0.8440009734728644,
    ],
  },
  ensemble: {
    accuracy: [
      0.7685568264784619,
      0.8283037235337065,
      0.8426624482842541,
      0.83603066439523,
      0.8523971769286931,
      0.8506935994159163,
      0.8619493794110489,
      0.8623752737892432,
      0.8586639084935508,
    ],
  },
}
